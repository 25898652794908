<template>
    <div class="container">
        <img class="img_" src="@/assets/image/404.png" alt="">
    </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {
    };
  },
  activated(){
  },
  mounted(){
  },
  methods:{
   
  }
};
</script>

<style scoped lang="less">
.container{
    width: 100%;
    height: 100%;
    .img_{
        width: 500px;
        margin: 0 auto;
        margin-top: 8%;
    }
}
@media screen and (max-width: 768px) {
    .container .img_ {
        width: 60%;
    }
}
</style>